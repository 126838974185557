(function ($) {
  ('use strict');

  $(document).ready(function () {
    $('.is_matchHeight').matchHeight();
  });

  // headerドロップメニュー SPメニュー
  $(document).ready(function () {
    // PC用のメニュー展開
    $('.navBtm_list > li > span').on('click', function () {
      if (window.matchMedia('(min-width: 840px)').matches) {
        var $underBox = $(this).next('.underBox');
        var isVisible = $underBox.is(':visible');
        $('.underBox').slideUp(); // 他の開いているメニューを閉じる
        if (!isVisible) {
          $underBox.stop(true, true).slideDown(); // クリックしたメニューを開閉
          $('.ly_header').addClass('js_header'); // メニューが開いたときにクラスを追加
          $('.bg_main').fadeIn(); // 背景を表示
        } else {
          $underBox.stop(true, true).slideUp(); // クリックしたメニューを閉じる
          $('.ly_header').removeClass('js_header'); // メニューが閉じたときにクラスを削除
          $('.bg_main').fadeOut(); // 背景を非表示
        }
      }
    });
    $('.is_linkAccess').on('click', function () {
      if (window.matchMedia('(min-width: 840px)').matches) {
        $('.underBox').slideUp();
        $('.ly_header').removeClass('js_header'); // メニューが閉じたらクラスを削除
        $('.bg_main').fadeOut(); // 背景を非表示
      }
    });
    // メニューの外をクリックした場合、PC用メニューを閉じる
    $(document).on('click', function (event) {
      if (window.matchMedia('(min-width: 840px)').matches) {
        if (!$(event.target).closest('.navBtm_list > li').length) {
          $('.underBox').slideUp();
          $('.ly_header').removeClass('js_header'); // メニューが閉じたらクラスを削除
          $('.bg_main').fadeOut(); // 背景を非表示
        }
      }
    });
    // SP用のメニュー展開
    $('.ly_header_menuIcon').on('click', function () {
      $('.navBtm').toggleClass('open');
      $(this).toggleClass('open');
      $('.sp_navInner').fadeToggle(); // SP用のメニューを表示・非表示
      $('.bg_main').fadeToggle(); // 背景の表示・非表示を切り替え
      $('.ly_header').toggleClass('js_header'); // SP用メニューが開いたときにクラスを追加/削除

      // ヘッダーの高さを取得してsp_navInnerのpadding-topに適用
      var headerHeight = $('.ly_header').outerHeight();
      $('.sp_navInner').css('padding-top', headerHeight + 'px');

      // メニューが開いている場合にbodyにoverflow: hiddenを付与
      if ($('.navBtm').hasClass('open')) {
        $('body').addClass('no-scroll');
      } else {
        // メニューが閉じたらoverflow: hiddenを解除
        $('body').removeClass('no-scroll');
      }
    });
    $('.sp_navInner_list01 > li > em').on('click', function () {
      if (window.matchMedia('(max-width: 839px)').matches) {
        $(this).toggleClass('open');
        $(this).next('.underBox_list').slideToggle(); // SP用のサブメニューを開閉
      }
    });
    $('.is_sp_linkAccess').on('click', function () {
      if (window.matchMedia('(max-width: 839px)').matches) {
        $('.ly_header_menuIcon').toggleClass('open');
        $('.sp_navInner').fadeToggle();
        $('.bg_main').fadeOut();
        $('body').removeClass('no-scroll');
      }
    });
    // ウィンドウサイズ変更時の処理
    $(window)
      .resize(function () {
        if (window.matchMedia('(min-width: 840px)').matches) {
          $('.navBtm_list').show();
          $('.underBox').hide();
          $('.navBtm').removeClass('open');
          $('.ly_header_menuIcon').removeClass('open');
          $('.bg_main').hide(); // 背景を非表示
          $('.sp_navInner').hide(); // SP用メニューを非表示
        } else {
          $('.navBtm_list').hide();
          $('.underBox').hide();
          $('.bg_main').hide(); // 背景を非表示
          $('.sp_navInner').hide(); // SP用メニューを非表示
        }
      })
      .resize();
    // スクロール時にヘッダーにクラスを付与
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 50) {
        $('.ly_header').addClass('js_scrolled');
      } else {
        $('.ly_header').removeClass('js_scrolled');
      }
    });
  });

  // footerドロップメニュー
  $(document).ready(function () {
    function checkWindowSize() {
      if (window.matchMedia('(max-width: 839px)').matches) {
        // SP表示用の処理
        $('.ftNav_arrow')
          .off('click')
          .on('click', function () {
            $(this).toggleClass('js_active');
            $(this).next('.ftNav_list').slideToggle();
          });
        $('.ftNav_list').hide(); // SPでは非表示にする
      } else {
        // PC表示用の処理
        $('.ftNav_arrow').off('click').removeClass('js_active');
        $('.ftNav_list').show(); // PCでは常に表示
      }
    }
    // 初回チェック
    checkWindowSize();
    // リサイズ時のチェック
    $(window).resize(function () {
      checkWindowSize();
    });
  });

  $(document).ready(function () {
    let isAnimating = false; // アニメーション中かどうかを示すフラグ

    // 最初のタブとコンテンツにactiveクラスを追加
    $('.carouselNav .tab').eq(0).addClass('active');
    $('.carouselBody .content').eq(0).addClass('active').show();
    $('.carouselSlides .imgContent').eq(0).addClass('active').show();

    // タブクリック時の処理
    $('.carouselNav .tab').on('click', function () {
      if (isAnimating) return; // アニメーション中の場合は何もしない
      isAnimating = true; // アニメーション中に設定

      var index = $(this).index(); // クリックされたタブのインデックスを取得

      // タブのactiveクラスを更新
      $('.carouselNav .tab').removeClass('active');
      $(this).addClass('active');

      // 左側コンテンツの表示をフェードで切り替え
      $('.carouselBody .content.active').fadeOut(30, function () {
        $(this).removeClass('active');
        $('.carouselBody .content')
          .eq(index)
          .addClass('active')
          .fadeIn(30, function () {
            isAnimating = false; // アニメーション完了後にフラグをリセット
          });
      });

      // 右側の画像コンテンツ（imgContent）の表示をフェードで切り替え
      $('.carouselSlides .imgContent.active').fadeOut(30, function () {
        $(this).removeClass('active');
        $('.carouselSlides .imgContent')
          .eq(index)
          .addClass('active')
          .fadeIn(30, function () {
            isAnimating = false; // アニメーション完了後にフラグをリセット
          });
      });
    });
  });
})(jQuery);
